/**
 *
 */
export class PurchaseSignatureError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PurchaseSignatureError)
    }

    this.name = "PurchaseSignatureError"
    this.date = new Date()

    this.type = type

    this.message = "There has been an error generating your purchase signature."
  }
}

/**
 *
 */
export class PurchaseTokenBalanceError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PurchaseTokenBalanceError)
    }

    this.name = "PurchaseTokenBalanceError"
    this.date = new Date()

    this.type = type

    this.message = "You do not have enough GEO tokens in your account to buy the dataset."
  }
}

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  // TODO: Authentication (ie. Bearer token)
  mode: "cors",
}

const get = async <T>(url: string, params: any) => {
  // optional query params
  if (params != null) {
    url += '?' + ( new URLSearchParams( params ) ).toString();
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers
    })

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json() as T
  } catch (err) {
    throw new Error(err);
  }
}

const post = async <T>(url: string, body: any) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json() as T
  } catch (err) {
    throw new Error(err);
  }
}

const _delete = async <T>(url: string, params: any) => {
  if (params != null) {
    url += '?' + ( new URLSearchParams( params ) ).toString();
  }

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers,
    })

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    return await response.json() as T
  } catch (err) {
    throw new Error(err);
  }
}

export const http = {
  get,
  post,
  delete: _delete
}
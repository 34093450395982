/**
 * Link list for navbar in header and footer
 */

export default [
  // {
  //   id: "crowdfunding",
  //   title: "layout.header.crowdfunding",
  //   type: "url-internal",
  //   link: "https://www.seedrs.com/geodb1",
  // },
  {
    id: "geoliquidity",
    title: "layout.header.geoliquidity",
    type: "url",
    link: "https://geyser.geodb.com",
  },
  // {
  //   id: "geooffering",
  //   title: "layout.header.geooffering",
  //   type: "url-internal",
  //   link: "https://geooffering.geodb.com",
  // },
  {
    id: "investors",
    title: "layout.header.investors",
    type: "url",
    link: "https://investors.geodb.com",
  },
  {
    id: "geodb",
    title: "layout.header.geodb.title",
    type: "group",
    links: [
      {
        id: "mission",
        title: "layout.header.geodb.links.mission.title",
        text: "layout.header.geodb.links.mission.text",
        type: "url",
        link: "https://geodb.com/mission",
      },
      // {
      //   id: "ecosystem",
      //   title: "layout.header.geodb.links.ecosystem.title",
      //   text: "layout.header.geodb.links.ecosystem.text",
      //   type: "link",
      //   link: "https://geodb.com/ecosystem",
      // },
      // {
      //   id: "token",
      //   title: "layout.header.geodb.links.token.title",
      //   text: "layout.header.geodb.links.token.text",
      //   type: "url",
      //   link: "https://tokensale.geodb.com/en/#distribution",
      // },
      {
        id: "roadmap",
        title: "layout.header.geodb.links.roadmap.title",
        text: "layout.header.geodb.links.roadmap.text",
        type: "url",
        link: "https://www.notion.so/geodatablock/GEO-ODIN-Roadmap-8e836e0c00924a7bac5cbb5c139e1b19",
      },
      // {
      //   id: "tokensale",
      //   title: "layout.header.geodb.links.tokensale.title",
      //   text: "layout.header.geodb.links.tokensale.text",
      //   type: "url",
      //   link: "https://tokensale.geodb.com/en/",
      // },
      // {
      //   id: "geooffering",
      //   title: "layout.header.geodb.links.geooffering.title",
      //   text: "layout.header.geodb.links.geooffering.text",
      //   type: "url",
      //   link: "https://geooffering.geodb.com",
      // },
      // {
      //   id: "swap",
      //   title: "layout.header.geodb.links.swap.title",
      //   text: "layout.header.geodb.links.swap.text",
      //   type: "url",
      //   link: "https://swap.geodb.com",
      // },
      // {
      //   id: "bridge",
      //   title: "layout.header.geodb.links.bridge.title",
      //   text: "layout.header.geodb.links.bridge.text",
      //   type: "url",
      //   link: "https://bridge.geodb.com",
      // },
      {
        id: "uniswap",
        title: "layout.header.geodb.links.uniswap.title",
        text: "layout.header.geodb.links.uniswap.text",
        type: "url",
        link:
          "https://info.uniswap.org/pair/0xd10122ef86ae040efee3f53f35d3247230ca670c",
      },
      {
        id: "pancakeswap",
        title: "layout.header.geodb.links.pancakeswap.title",
        text: "layout.header.geodb.links.pancakeswap.text",
        type: "url",
        link: "https://exchange.pancakeswap.finance/#/swap",
      },
      // {
      //   id: "odinswap",
      //   title: "layout.header.geodb.links.odinswap.title",
      //   text: "layout.header.geodb.links.odinswap.text",
      //   type: "url",
      //   link: "https://odinswap.geodb.com/",
      // },
    ],
  },
  {
    id: "documentation",
    title: "layout.header.documentation.title",
    type: "group",
    links: [
      {
        id: "whitepaper",
        title: "layout.header.documentation.links.whitepaper.title",
        text: "layout.header.documentation.links.whitepaper.text",
        // type: "link",
        // link: "https://geodb.com/white-paper",
        type: "pdf",
        link: "https://geodb.com/docs/odin-whitepaper.pdf",
        // link: "https://geodb.com/docs/geodb-white-paper.pdf",
        // fileName: "GeoDB_Business_Memorandum_2.1.pdf",
      },
      {
        id: "technicalpaper",
        title: "layout.header.documentation.links.technicalpaper.title",
        text: "layout.header.documentation.links.technicalpaper.text",
        // type: "link",
        // link: "https://geodb.com/technical-paper",
        type: "pdf",
        link: "https://geodb.com/docs/odin-tokenomics.pdf",
        // link: "https://geodb.com/docs/geodb-technical-paper.pdf",
        // fileName: "GeoDB_Technical_White_Paper_2.0.pdf",
      },
      // {
      //   id: "offeringpaper",
      //   title: "layout.header.documentation.links.offeringpaper.title",
      //   text: "layout.header.documentation.links.offeringpaper.text",
      //   // type: "link",
      //   // link: "https://geodb.com/technical-paper",
      //   type: "pdf",
      //   link: "https://geodb.com/docs/geodb-defi-geo-offering-motivational-paper.pdf",
      //   // link: "https://geodb.com/docs/geodb-technical-paper.pdf",
      //   // fileName: "GeoDB_Technical_White_Paper_2.0.pdf",
      // },
      {
        id: "faq",
        title: "layout.header.documentation.links.faq.title",
        text: "layout.header.documentation.links.faq.text",
        type: "url",
        link: "https://docs.geodb.com",
      },
    ],
  },
  {
    id: "company",
    title: "layout.header.company.title",
    type: "group",
    links: [
      {
        id: "team",
        title: "layout.header.company.links.team.title",
        text: "layout.header.company.links.team.text",
        type: "link",
        link: "https://geodb.com/team",
      },
      {
        id: "blog",
        title: "layout.header.company.links.blog.title",
        text: "layout.header.company.links.blog.text",
        type: "url",
        link: "https://geodb.medium.com/",
      },
    ],
  },
  {
    id: "legal",
    title: "layout.header.legal.title",
    type: "group",
    links: [
      {
        id: "terms",
        title: "layout.header.legal.links.terms.title",
        // text: "",
        type: "link",
        link: "https://geodb.com/terms",
      },
      {
        id: "privacy",
        title: "layout.header.legal.links.privacy.title",
        // text: "",
        type: "link",
        link: "https://geodb.com/privacy",
      },
    ],
  },
]

import useWallet from "../wallet/useWallet"
import {
  getEstimatedPrice,
  getAccountTokenBalance,
  checkAllowance,
  approveDatasetPurchase,
  purchaseDataset,
} from "./services"

export default function useContract() {
  const { active, account, library } = useWallet()

  function checkWallet() {
    if (!account || !active) {
      throw new Error("WALLET_NOT_CONNECTED")
    }
  }

  async function estimateDatasetPrice(numPoints) {
    checkWallet()
    return await getEstimatedPrice({ numPoints, signer: library.getSigner() })
  }

  async function checkAlreadyApproved() {
    checkWallet()
    return await checkAllowance({ owner: account, signer: library.getSigner() })
  }

  async function getAccountBalance() {
    checkWallet()
    return await getAccountTokenBalance({
      owner: account,
      signer: library.getSigner(),
    })
  }

  function requestPurchaseApproval({ amount, onSuccess, onError }) {
    checkWallet()
    return approveDatasetPurchase({
      signer: library.getSigner(),
      amount,
      onSuccess,
      onError,
    })
  }

  function requestPurchaseDataset({
    datasetId,
    purchaseSign,
    price,
    onSuccess,
    onError,
  }) {
    checkWallet()

    return purchaseDataset({
      signer: library.getSigner(),
      holder: account,
      datasetId,
      purchaseSign,
      price,
      onSuccess,
      onError,
    })
  }

  return {
    estimateDatasetPrice,
    getAccountBalance,
    checkAlreadyApproved,
    requestPurchaseApproval,
    requestPurchaseDataset,
  }
}

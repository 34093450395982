import React from "react"

import { Flex, Badge, Icon } from "marketplace/app/components"
import CartImg from "marketplace/app/images/icons/cart.svg"

import useCart from "../hooks"

const CartIconButton = () => {
  const { cart } = useCart()

  return (
    <Flex
      sx={{
        height: "40px",
        width: "40px",
      }}
    >
      <Icon path={CartImg} title="cart" width={27} />
      {!!cart.length && <Badge ml={"-5px"}>{cart.length}</Badge>}
    </Flex>
  )
}

export default CartIconButton

import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"

// import { BLOCKCHAIN } from "marketplace/web3/config"

const BLOCKCHAIN = {
  CHAIN_ID: process.env.GATSBY_CHAIN_ID,
  POLLING_INTERVAL: process.env.GATSBY_POLLING_INTERVAL,
  PROVIDER_URL: process.env.GATSBY_PROVIDER_URL,
}

/**
 * Creates a Metamask connector
 */

const getMetamaskConnector = () => {
  return new InjectedConnector({
    supportedChainIds: [parseInt(BLOCKCHAIN.CHAIN_ID)],
  })
}

/**
 * Creates a Wallet Connect connector
 */

const getWalletConnectConnector = () => {
  const rpc = {}
  rpc[BLOCKCHAIN.CHAIN_ID] = BLOCKCHAIN.PROVIDER_URL
  return new WalletConnectConnector({
    rpc,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: BLOCKCHAIN.POLLING_INTERVAL,
  })
}

export default {
  metamask: getMetamaskConnector(),
  walletConnect: getWalletConnectConnector(),
}

import React from "react"
import PropTypes from "prop-types"

import { Flex, Text, Icon } from "marketplace/app/components"
import MetamaskImg from "marketplace/app/images/icons/metamask-circle.svg"

function formatAddress(string) {
  return string.slice(0, 5) + " . . . " + string.slice(-4)
}

const AddressBadge = ({ address }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleExpanded = () => {
    setIsExpanded(prev => !prev)
  }

  return (
    <Flex
      title={address}
      onClick={toggleExpanded}
      sx={{
        bg: "muted",
        p: "8px",
        borderRadius: "4px",
        boxShadow: "0px 2px 4px rgba(43, 41, 45, 0.237434)",
        cursor: "pointer"
      }}
    >
      <Icon
        path={MetamaskImg}
        sx={{ mr: "8px" }}
      />
      <Text variant="text.textSmall">
        {isExpanded ? address : formatAddress(address)}
      </Text>
    </Flex>
  )
}

AddressBadge.propTypes = {
  address: PropTypes.string.isRequired,
}

export default AddressBadge

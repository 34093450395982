import React, { useState } from "react"
import { Flex, Div, Text, Icon } from "marketplace/app/components"
import crossIcon from "marketplace/app/images/icons/cross.svg"
import lineIcon from "marketplace/app/images/icons/line.svg"

const ToggleBlock = ({ question, children }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Div onClick={() => setExpanded(prev => !prev)} sx={{ cursor: "pointer" }}>
      <Div sx={{ mb: "40px" }}>
        <ul
          style={{
            marginBlockStart: "0px",
            marginBlockEnd: "0px",
            paddingInlineStart: ["20px", "20px", "40px", "40px"],
          }}
        >
          <li
            style={{
              listStyleImage: `url(${expanded ? lineIcon : crossIcon})`,
            }}
          >
            <Text
              variant="text.tableHeader"
              sx={{ ml: [null, null, "10px", "10px"] }}
            >
              {question}
            </Text>
          </li>
        </ul>
      </Div>
      <Div
        sx={{
          display: expanded ? "flex" : "none",
          flexDirection: "column",
          ml: ["20px", "20px", "40px", "40px"],
          mb: "10px",
        }}
      >
        {children}
      </Div>
    </Div>
  )
}

export default ToggleBlock

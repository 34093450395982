import React from "react"

import {
  ModalDialog,
  Heading,
  Flex,
  Text,
  ButtonAction,
  Input,
} from "marketplace/app/components"

const DatasetEditModal = ({
  dataset,
  content,
  isOpen,
  closeDialog,
  inputValue,
  editHandler,
  submitHandler,
}) => {
  return (
    <ModalDialog show={isOpen} onClose={closeDialog} ariaLabel="Attention!">
      {content?.title && (
        <Heading variant="text.previewTitle" sx={{ mb: "32px" }}>
          {content.title}
        </Heading>
      )}
      <Flex column>
        <Text
          variant="text.textBody"
          sx={{ maxWidth: "500px", mb: "40px", mx: "auto" }}
        >
          {content.description}
        </Text>
        <Input
          placeholder={dataset.name}
          value={inputValue}
          onChange={editHandler}
          sx={{
            variant: "text.textBody",
            width: "100%",
            maxWidth: "500px",
            height: "60px",
            outline: "none",
            "&::placeholder": {
              opacity: 1,
              color: "muted",
            },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mb: "40px",
            mx: "auto",
            borderColor: "primary",
            borderStyle: "solid",
            borderRadius: "8px",
            px: "10px",
          }}
        />
      </Flex>
      <ButtonAction onClick={submitHandler}>{content.button}</ButtonAction>
    </ModalDialog>
  )
}

export default DatasetEditModal

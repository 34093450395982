import { ethers } from "ethers"

import MARKETPLACE_ABI from "../abis/marketplace.json"
import GEOTOKEN_ABI from "../abis/geotoken-bsc.json"

const CONTRACTS = {
  marketplace: {
    abi: MARKETPLACE_ABI,
    address: process.env.GATSBY_MARKETPLACE_ADDRESS,
  },
  geotoken: {
    abi: GEOTOKEN_ABI,
    address: process.env.GATSBY_GEOTOKEN_ADDRESS,
  },
}

function getContractConfig(contractName) {
  switch (contractName) {
    case "marketplace":
      return { ...CONTRACTS.marketplace }
    case "geotoken":
      return { ...CONTRACTS.geotoken }
    default:
      throw new Error("INVALID CONTRACT")
  }
}

/**
 * Create a Contract using ethers.js
 */

export default function getContract({ contractName, signer }) {
  const { abi, address } = getContractConfig(contractName)
  return new ethers.Contract(address, abi, signer)
}

import React from "react"
// import { UnsupportedChainIdError } from "@web3-react/core"

import {
  ColumLayout,
  Flex,
  Heading,
  ButtonAction,
} from "marketplace/app/components"

const CHAIN_ID = process.env.GATSBY_CHAIN_ID

function getChainIdErrorMessage() {
  if (CHAIN_ID === '97') {
    return "Connect your wallet to the BSC Testnet network"
  }
  if (CHAIN_ID === '56') {
    return "Connect your wallet to the BSC Mainnet network"
  }
  return "Wallet Chain ID Error"
}

const ErrorPanel = ({ error, resetErrorBoundary }) => {
  const renderBody = () => {
    if (error.message === ("FETCH_HTTP_ERROR" || "FETCH_NETWORK_ERROR")) {
      return "Preview data is not available at this time"
    }

    // if (error instanceof UnsupportedChainIdError) {
    //   return error.message
    // }
    if (error.message === "WALLET_CHAIN_ID_ERROR") {
      return getChainIdErrorMessage()
    }
    if (error.message === "UNKNOWN_WALLET") {
      return "Unkown Wallet Error"
    }
    if (error.message === "WALLET_ERROR") {
      return "Wallet Error"
    }
  }

  return (
    <ColumLayout overlay centered backgroundColor="secondary">
      <Heading
        variant="text.previewTitle"
        sx={{ mb: "50px", color: "background" }}
      >
        Something went wrong!
      </Heading>
      <Heading
        variant="text.smallTitle"
        sx={{ mb: "50px", color: "background" }}
      >
        {renderBody()}
      </Heading>
      <Flex
        sx={{
          width: "100%",
          mx: "auto",
          justifyContent: "center",
        }}
      >
        {resetErrorBoundary && (
          <ButtonAction onClick={resetErrorBoundary}>Try again</ButtonAction>
        )}
      </Flex>
    </ColumLayout>
  )
}

export default ErrorPanel

import React from "react"

import { FlexFadeOut, Spinner } from ".."

const LoadingCoverAnime = ({transparency = true, spinner = true}) => {
  return (
    <FlexFadeOut
      animation
      sx={{
        // flex: 1,
        width: "100%",
        height: "100%",
        zIndex: 10,
        flexDirection: "column",
        position: "absolute",
        // p: "55px",
        bg: "background",
        // backgroundColor: transparency ? "rgba(255, 255, 255, 0.6)" : "background",
        // backgroundColor: transparency ? "rgba(255, 255, 255, 0.6)" : "background",
        //
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {spinner && <Spinner
        title="loading..."
        size={80}
        strokeWidth={3}
        sx={{
          color: "secondary",
          mx: "auto",
          mb: "50px",
          // zIndex: 10,
          // mt: ["120px", "200px", "250px", "250px"],
        }}
      />}
      {/* </Flex> */}
    </FlexFadeOut>
  )
}

export default LoadingCoverAnime

import createRequest from "./utils/create-request"
import createTransaction from "./utils/create-transaction"
import { getEtherStringFromBigNumber } from "./utils/unit-conversion"
import { AllowanceTokenError, BalanceTokenError, ApproveTokenError, PurchaseTxError } from "./errors"


const marketplaceAddress = process.env.GATSBY_MARKETPLACE_ADDRESS
const tokenAddress = process.env.GATSBY_GEOTOKEN_ADDRESS

/**
 * 
 * Ask the Marketplace contract to calculate the estimated 
 * price for the dataset (that is, a number of points)
 * 
 */
export async function getEstimatedPrice({ numPoints, signer }) {
  try {
    const request = createRequest({
      contractName: "marketplace",
      methodName: "computePrice",
      signer
    })

    const response = await request({ inputs: [numPoints] })
    return getEtherStringFromBigNumber(response)

  } catch (err) {
    // TODO: custom error
    console.error("Error in getEstimatedPrice service", err)
  }
}


/**
 * 
 * Ask the Marketplace contract to calculate the estimated 
 * price for the dataset (that is, a number of points)
 * 
 */
export async function checkAllowance({ owner, signer }) {
  // marketplaceAddress, aka. spender
  try {
    const request = createRequest({
      contractName: "geotoken",
      methodName: "allowance",
      signer
    })

    const response = await request({ inputs: [owner, marketplaceAddress] })
    return getEtherStringFromBigNumber(response)

  } catch (err) {
    throw new AllowanceTokenError()
  }
}

/**
 * 
 * 
 */
export async function getAccountTokenBalance({ owner, signer }) {
  try {
    const request = createRequest({
      contractName: "geotoken",
      methodName: "balanceOf",
      signer
    })

    const response = await request({ inputs: [owner] })
    return getEtherStringFromBigNumber(response)

  } catch (err) {
    throw new BalanceTokenError()
  }
}

/**
 * 
 * Approve in the GeoToken contract that the Marketplace contract 
 * can withdraw a certain amount of GEO for the purchase of data.
 * 
 */
export function approveDatasetPurchase({ amount, signer, onSuccess, onError }) {
  // marketplaceAddress, aka. spender

  const approveTx = createTransaction({
    contractName: "geotoken",
    methodName: "approve",
    signer
  })

  const errorHandler = err => {
    let error
    if (err.code && err.code === 4001) {
      // "code": 4001, "message": "MetaMask Tx Signature: User denied transaction signature."
      error = new ApproveTokenError("cancel")
    } else {
      error = new ApproveTokenError()
    }
    onError(error)
  }

  approveTx({
    inputs: [marketplaceAddress, amount],
    onTransactionHash: console.info, // TODO: leave as comment
    onTransactionConfirmation: onSuccess,
    onTransactionError: errorHandler,
  })

}


/**
 * 
 * Make the purchase transaction in the Marketplace contract.
 * 
 */
export function purchaseDataset({ signer, holder, datasetId, purchaseSign, price, onSuccess, onError }) {
  // 

  const purchaseTx = createTransaction({
    contractName: "marketplace",
    methodName: "purchaseDatasetFor",
    signer
  })

  const errorHandler = err => {
    // to assign custom Error
    let error

    if (err.code && err.code === 4001) {
      // "code": 4001, "message": "MetaMask Tx Signature: User denied transaction signature."
      error = new PurchaseTxError("cancel")
    } else if (err.code && err.code === -32603) {
      // Tx rejected in the contract - signature already used
      //     "code": -32603,
      //     "message": "Internal JSON-RPC error.",
      //     "data": {
      //         "code": 3,
      //         "message": "execution reverted: DataMarketplace.purchaseDatasetFor() - Signature already used",
      //         "data": "0x08..."

      // {
      //   "code": -32603,
      //   "message": "Internal JSON-RPC error.",
      //   "data": {
      //       "code": 3,
      //       "message": "execution reverted: DataMarketplace - Not signer",
      //       "data": "0x08c379a00000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000001c446174614d61726b6574706c616365202d204e6f74207369676e657200000000"
      //   }
      // }

      // {
      //   "code": -32603,
      //   "message": "Internal JSON-RPC error.",
      //   "data": {
      //       "code": 3,
      //       "message": "execution reverted: BEP20: transfer amount exceeds balance",
      //       "data": "0x08c379a00000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000002642455032303a207472616e7366657220616d6f756e7420657863656564732062616c616e63650000000000000000000000000000000000000000000000000000"
      //   }
      // }

      error = new PurchaseTxError()
    } else {
      // TODO: Tx rejected in the contract - not approved first..
      error = new PurchaseTxError()
    }

    onError(error)
  }

  const inputs = [
    datasetId, price, tokenAddress, holder, purchaseSign.v, purchaseSign.r, purchaseSign.s
  ]

  purchaseTx({
    inputs,
    onTransactionHash: console.info,
    onTransactionConfirmation: onSuccess,
    onTransactionError: errorHandler,
  })
}

import { http } from "marketplace/app/http/http"
import { API } from "marketplace/app/config"

import { DatasetsAddressError, DatasetDownloadUrlError, DatasetDeleteError } from "./errors"


export async function getOrdersByAddress(address) {
  try {
    const response = await http.get(`${API.orders}/${address}`)
    if (!response) throw new Error()
    return response
  } catch (err) {
    throw new DatasetsAddressError()
  }
}

// TODO: queryParams => IQueryDownloadUrl
export async function getDatasetDownloadUrl({ userSign, datasetId }) {
  try {
    const queryParams = { user_sign: userSign.flatSign, hashed_key: userSign.msgHash, }
    const response = await http.get(`${API.ordersLink}/${datasetId}`, queryParams)
    if (!response) throw new Error()
    return response
  } catch (err) {
    throw new DatasetDownloadUrlError()
  }
}


export async function deletePendingOrder({ userSign, datasetId }) {
  try {
    const queryParams = { user_sign: userSign.flatSign, hashed_key: userSign.msgHash, }
    const response = await http.delete(`${API.orders}/${datasetId}/delete`, queryParams)
    if (!response) throw new Error()
    return response
  } catch (err) {
    throw new DatasetDeleteError()
  }
}

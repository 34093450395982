/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ path, title, width, height, sx }) => (
  <img
    src={path}
    alt={title}
    width={width}
    height={height}
    sx={{
      verticalAlign: "middle",
      ...sx,
    }}
  />
)

export default Icon

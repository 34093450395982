/** @jsx jsx */
import { jsx } from "theme-ui"

const Nav = ({ onClick, sx, children }) => (
  <nav onClick={onClick} sx={{ ...sx }}>
    {children}
  </nav>
)

export default Nav

import React from "react"
import { useErrorHandler } from "react-error-boundary"

import {
  ColumLayout,
  Flex,
  Heading,
  MetamaskButton
} from "marketplace/app/components"

import { useWallet } from "marketplace/web3"

const MetamaskPanel = () => {
  const { connect, walletError } = useWallet()
  
  const handleError = useErrorHandler()

  React.useEffect(() => {
    if (walletError) handleError(walletError)
  }, [walletError])

  return (
    <ColumLayout centered sx={{
      minHeight: ["500px", "550px", "600px", "790px"],
      height: "auto"}}>
      <Heading variant="text.smallTitle" sx={{ mb: "50px", maxWidth: "475px", width: "fit-content", mx: "auto" }}>
        To be able to access your information you must access through Metamask!
      </Heading>
      <Flex
        sx={{
          width: "100%",
          mx: "auto",
          justifyContent: "center",
        }}
      >
        <MetamaskButton clickHandler={() => connect("metamask")} />
      </Flex>
    </ColumLayout>
  )
}

export default MetamaskPanel

import React from "react"

import {
  Flex,
  Text,
  ButtonIcon,
  Icon,
  Spinner,
  PriceLabel,
} from "marketplace/app/components"

import { getEtherStringFromWeiString } from "marketplace/web3/contracts/utils/unit-conversion"

import CloseImg from "marketplace/app/images/icons/close-red.svg"
import RadioEmptyImg from "marketplace/app/images/icons/radio-empty.svg"
import RadioSelectedImg from "marketplace/app/images/icons/radio-selected.svg"
import DotImg from "marketplace/app/images/geodb-icon.png"

const priceStyle = {
  variant: "text.textCartItem",
  fontFamily: "heading",
  color: "primary",
  alignSelf: "center",
}

const RadioSelector = ({ path }) => (
  <Icon path={path} width="16" height="16" sx={{ m: "auto" }} />
)

const CartItem = ({
  dataset,
  selected,
  onSelect,
  onDelete,
  datasetRemove,
  removing,
  onEdit,
}) => {
  // we have two types of dataset at this point:
  //    * pre-order: coming from search module
  //    * order: previously registered (at least purchase signs generated)

  const isDatasetSelected =
    selected &&
    ((selected.uuid && selected.uuid === dataset.uuid) ||
      (selected.id && selected.id === dataset.id))

  const price =
    dataset.grossPrice ||
    (dataset.price && getEtherStringFromWeiString(dataset.price))

  return (
    <Flex
      sx={{
        mb: "10px",
        maxWidth: "680px",
        alignItems: "baseline",
        justifyContent: "space-between",
      }}
    >
      <Flex
        sx={{ flex: 11, cursor: "pointer" }}
        onClick={() => onSelect(dataset)}
      >
        <Flex sx={{ flex: 1 }}>
          {isDatasetSelected && <RadioSelector path={RadioSelectedImg} />}
          {!isDatasetSelected && <RadioSelector path={RadioEmptyImg} />}
        </Flex>

        <Flex
          sx={{
            flex: 10,
            opacity: !isDatasetSelected && 0.4,
          }}
        >
          <Flex sx={{ flex: 2, whiteSpace: "nowrap", overflow: "hidden" }}>
            <Text
              variant="text.textCartItem"
              sx={{
                fontFamily: "heading",
                alignSelf: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dataset.name}
            </Text>
          </Flex>
          {/* <Flex
            sx={{
              flex: 1,
              display: ["none", "inherit", "inherit", "inherit"],
              justifyContent: "center",
            }}
          >
            <Text
              variant="text.textCartItem"
              sx={{
                maxWidth: "200px",
                minWidth: "50px",
                width: "fit-content",
                overflow: "hidden",
                whiteSpace: "nowrap",
                px: "20px",
                alignSelf: "center",
              }}
            >
              <small>*******************</small>
            </Text>
          </Flex> */}
          <Flex sx={{ flex: 2, justifyContent: "flex-end" }}>
            <PriceLabel price={price} sxInt={priceStyle} />
          </Flex>
        </Flex>
      </Flex>

      <Flex sx={{ flex: 2, justifyContent: "center", m: "auto" }}>
        <Flex sx={{ flex: 1 }} />
        {/* Edit */}
        {dataset.uuid && (
          <ButtonIcon
            src={DotImg}
            onClick={() => onEdit(dataset)}
            alt="Edit name"
            title="Edit name"
            sx={{ width: "18px", height: "18px", ml: "15px" }}
          />
        )}
        {/* Remove */}
        {removing && datasetRemove.id === dataset.id ? (
          <Spinner
            title="removing..."
            size={22}
            strokeWidth={3}
            sx={{ color: "secondary" }}
          />
        ) : (
          <ButtonIcon
            src={CloseImg}
            onClick={() => onDelete(dataset)}
            alt="Remove"
            title="Remove"
          />
        )}
      </Flex>
    </Flex>
  )
}

export default CartItem

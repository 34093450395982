/** @jsx jsx */
import { jsx } from "theme-ui"

const ButtonIcon = ({ src, alt, title, onClick, sx }) => (
  <button
    title={title}
    onClick={onClick}
    sx={{
      cursor: "pointer",
      background: "none",
      border: "none",
    }}
  >
    <img src={src} alt={alt} sx={{ ...sx }} />
  </button>
)

export default ButtonIcon

import React from "react"

import { Page, TitleContentPanel, Heading, Text, ContentPanel } from ".."

const PageView = ({ seo, title, description, styleName, children }) => {
  const getStyle = () => {
    if (styleName === "black") {
      return { bg: "text", color: "background" }
    }
    if (styleName === "blue") {
      return { bg: "muted" }
    }
    if (styleName === "red") {
      return { bg: "secondary", color: "background" }
    }
  }

  return (
    <Page seo={seo}>
      <TitleContentPanel outerSx={getStyle()}>
        <Heading variant="text.pageTitle">{title}</Heading>
        <Text variant="text.textBody" sx={{ width: "100%", maxWidth: ["100%","100%","100%","480px"] }}>
          {description}
        </Text>
      </TitleContentPanel>
      <ContentPanel sx={{ position: "relative" }}>{children}</ContentPanel>
    </Page>
  )
}

export default PageView

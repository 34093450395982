import React from "react"

import { useCartContext } from "../context"

export default function useCart() {
  const {
    state,
    fetching,
    addDatasetToCart,
    removeDataset,
    removePendingOrder,
    removing,
    updateDatasetName,
  } = useCartContext()
  const { cart } = state

  const removeDatasetFromCart = (idType, idValue) => {
    if (idType === "id") {
      removePendingOrder(idValue)
    } else if (idType === "uuid") {
      removeDataset(idValue)
    }
  }

  const purchaseSuccessCartHandler = uuid => {
    removeDataset(uuid)
  }

  const updateDatasetNameFromCart = (uuid, newName) => {
    updateDatasetName(uuid, newName)
  }

  return {
    cart,
    fetching,
    addDatasetToCart,
    removeDatasetFromCart,
    updateDatasetNameFromCart,
    removing,
    purchaseSuccessCartHandler
  }
}

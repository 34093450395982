import React from "react"

import { formatPrice } from "marketplace/app/utils"

import { Div } from ".."

const PriceLabel = ({ price, sxInt, sxDec }) => {
  const priceFormatted = formatPrice(price)
  return (
    <Div title={`${price} GEO`} sx={sxInt}>
      {priceFormatted[0]}
      {"."}
      <Div
        span
        sx={{ variant: "text.textSmall", fontFamily: "heading", ...sxDec }}
      >
        {priceFormatted[1].substring(0,2)}
      </Div>
      {" GEO"}
    </Div>
  )
}

export default PriceLabel

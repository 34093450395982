/**
 *
 */
export class UserSignatureError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserSignatureError)
    }

    this.name = "UserSignatureError"
    this.date = new Date()

    this.type = type

    if (type === "cancel") {
      this.message =
        "You have just canceled your personal signature from MetaMask. This step is necessary to associate the purchase process with you."
    } else {
      this.message =
        "There has been a problem making your personal signature with MetaMask."
    }
  }
}

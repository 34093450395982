import React from "react"

import {
  ModalDialog,
  Heading,
  Text,
  ButtonAction,
} from "marketplace/app/components"

const ConfirmationModal = ({ content, isOpen, closeDialog, submitHandler }) => {
  return (
    <ModalDialog show={isOpen} onClose={closeDialog} ariaLabel="Attention!">
      {content?.title && (<Heading variant="text.previewTitle" sx={{ mb: "32px" }}>
        {content.title}
      </Heading>)}
      <Text
        variant="text.textBody"
        sx={{ maxWidth: "500px", mb: "40px", mx: "auto" }}
      >
        {content.description}
      </Text>
      <ButtonAction onClick={submitHandler}>{content.button}</ButtonAction>
    </ModalDialog>
  )
}

export default ConfirmationModal

/** @jsx jsx */
import { jsx, Spinner as SpinnerThemeUI } from "theme-ui"

const Spinner = ({ title = "loading", size, strokeWidth, sx }) => (
  <SpinnerThemeUI
    title={title}
    size={size}
    strokeWidth={strokeWidth}
    sx={{ ...sx }}
  />
)

export default Spinner

import React from "react"
import { v4 as uuidv4 } from "uuid"

import useOrders from "marketplace/orders/hooks/useOrders"

/**
 *
 */

export const CartContext = React.createContext(null)

/**
 *
 */

export function useCartContext() {
  const context = React.useContext(CartContext)

  if (!context) {
    throw new Error("Hook useCartContext must be used within an CartProvider")
  }

  return context
}

/**
 *
 */

const INITIAL_STATE = {
  cart: [],
  // processing: false,
  selectedDataset: null,
  status: "idle",
}

/**
 *
 */

export const cartReducer = (state, action) => {
  if (action.type === "ADD_PENDING_ORDERS_TO_CART") {
    return {
      ...state,
      cart: [...state.cart, ...action.payload],
    }
  }

  if (action.type === "ADD_DATASET_TO_CART") {
    return {
      ...state,
      cart: [
        ...state.cart,
        {
          uuid: uuidv4(),
          ...action.payload,
        },
      ],
    }
  }

  if (action.type === "REMOVE_DATASET_FROM_CART") {
    const { uuid } = action.payload
    const newCart = state.cart.filter(dataset => dataset.uuid !== uuid)
    return {
      ...state,
      cart: newCart,
    }
  }

  if (action.type === "REMOVE_PENDING_ORDER_FROM_CART") {
    const { id } = action.payload
    const newCart = state.cart.filter(dataset => dataset.id !== id)
    return {
      ...state,
      cart: newCart,
    }
  }

  if (action.type === "UPDATE_DATASET_NAME") {
    const { uuid, newName } = action.payload
    const newCart = state.cart.map(dataset => {
      if (dataset.uuid === uuid) {
        dataset.name = newName
      }
      return dataset
    })
    return {
      ...state,
      cart: newCart,
    }
  }

  throw new Error(
    `Cart Reducer Error: The indicated action could not be resolved: ${action.type}`
  )
}

/**
 *
 */

const CartProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(cartReducer, INITIAL_STATE)
  const { cart } = state

  const { pendingOrders, fetching, removePendingOrder, removing } = useOrders()

  React.useEffect(() => {
    if (cart.length === 0 && pendingOrders && pendingOrders.length > 0) {
      addPendingOrders(pendingOrders)
    }
  }, [pendingOrders])

  const addPendingOrders = datasets => {
    dispatch({
      type: "ADD_PENDING_ORDERS_TO_CART",
      payload: datasets,
    })
  }

  const addDatasetToCart = dataset => {
    dispatch({
      type: "ADD_DATASET_TO_CART",
      payload: dataset,
    })
  }

  // Remove dataset from cart it's been used in 3 use cases:
  // * Remove not approved
  // * Remove approved
  // * Remove purchased
  const removeDataset = uuid => {
    dispatch({
      type: "REMOVE_DATASET_FROM_CART",
      payload: { uuid },
    })
  }

  const removePendingOrderFromCart = async id => {
    const response = await removePendingOrder(id)
    if (response) {
      dispatch({
        type: "REMOVE_PENDING_ORDER_FROM_CART",
        payload: { id },
      })
    }
  }

  const updateDatasetName = (uuid, newName) => {
    dispatch({
      type: "UPDATE_DATASET_NAME",
      payload: { uuid, newName },
    })
  }

  return (
    <CartContext.Provider
      value={{
        state,
        fetching,
        addDatasetToCart,
        removeDataset,
        removePendingOrder: removePendingOrderFromCart,
        removing,
        updateDatasetName,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider

import React from "react"
import { ErrorBoundary, useErrorHandler } from "react-error-boundary"

import { useWallet } from "marketplace/web3"
import {
  MetamaskPanel,
  EmptyPanel,
  ErrorPanel,
  LoadingCover,
} from "marketplace/app/components"

import useCart from "../hooks"
import useCheckout from "../hooks/useCheckout"

import CartList from "./CartList"
import PurchaseModal from "./PurchaseModal"

const Cart = () => {
  const { active, resetWalletError } = useWallet()
  const {
    cart,
    fetching,
    removeDatasetFromCart,
    removing,
    updateDatasetNameFromCart,
  } = useCart()

  const {
    status,
    selectedDataset,
    error,
    processing,
    toggleSelected,
    resetSelected,
    startPurchaseHandler,
    resetPurchase,
    retryPurchase,
    successHandler,
  } = useCheckout()

  const [datasetRemove, setDatasetRemove] = React.useState(null)

  React.useEffect(() => {
    if (!removing && datasetRemove) {
      setDatasetRemove(null)
    }
  }, [removing])

  const resetHandler = () => {
    resetPurchase()
  }

  const retryHandler = () => {
    retryPurchase()
  }

  const deleteHandler = dataset => {
    if (dataset.id && dataset.status === "pending-order") {
      removeDatasetFromCart("id", dataset.id)
    } else if (dataset.uuid) {
      removeDatasetFromCart("uuid", dataset.uuid)
    }
  }

  const renderView = () => {
    if (!active) return <MetamaskPanel />
    if (!cart.length) return <EmptyPanel type="cart" />
    if (cart.length)
      return (
        <>
          {fetching && <LoadingCover transparency />}

          <CartList
            cart={cart}
            removeDatasetFromCart={deleteHandler}
            removing={removing}
            datasetRemove={datasetRemove}
            setDatasetRemove={setDatasetRemove}
            status={status}
            selectedDataset={selectedDataset}
            toggleSelected={toggleSelected}
            resetSelected={resetSelected}
            startPurchaseHandler={startPurchaseHandler}
            updateDatasetNameHandler={updateDatasetNameFromCart}
          />

          <PurchaseModal
            isOpen={processing}
            status={status}
            error={error}
            resetHandler={resetHandler}
            retryHandler={retryHandler}
            successHandler={successHandler}
          />
        </>
      )
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPanel} onReset={resetWalletError}>
      {processing && <LoadingCover transparency spinner={false} />}
      {renderView()}
    </ErrorBoundary>
  )
}

export default Cart

import React from "react"

import { Link, Button, Text } from ".."

const SECONDARY_TOKEN = "buttons.secondary"

const ButtonLink = ({ to, secondary, variant, sx, children }) => (
  <Link to={to}>
    <Button variant={secondary ? SECONDARY_TOKEN : variant} sx={sx}>
      <Text variant="text.textSmall">{children}</Text>
    </Button>
  </Link>
)

export default ButtonLink

import { http } from "marketplace/app/http/http"
import { API } from "marketplace/app/config"
import { IQueryParams } from "./types"

import { PurchaseSignatureError } from "./errors"


// returns grossPrice, purchaseSign, datasetId
export async function generatePurchaseSign({ name, userSign, datasetParams }) {
  try {
    const queryParams = {
      name: name,
      user_sign: userSign.flatSign,
      hashed_key: userSign.msgHash,
      dataset_params: {
        polygon: datasetParams.spatial || datasetParams.polygon,
        startDate: datasetParams.time?.start || datasetParams.startDate,
        endDate: datasetParams.time?.end || datasetParams.endDate,
      }
    }

    const response = await http.post(`${API.purchaseSignature}`, queryParams)

    if (!response) throw new Error()

    return response
  } catch (err) {
    throw new PurchaseSignatureError()
  }

}





































import React from "react"
import PropTypes from "prop-types"

import { Flex } from ".."

const FullViewport = ({ children }) => (
  <Flex column sx={{ minHeight: "100vh" }}>
    {children}
  </Flex>
)

FullViewport.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullViewport

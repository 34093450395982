import { ethers } from "ethers"

export function getEtherStringFromBigNumber(bigNum) {
  return ethers.utils.formatEther(bigNum)
}

export function getWeiStringFromBigNumber(bigNum) {
  return ethers.utils.formatUnits(bigNum, "wei")
}

export function getEtherStringFromWeiString(weiStr) {
  const bigNum = ethers.utils.parseUnits(weiStr, "wei")
  return getEtherStringFromBigNumber(bigNum)
}

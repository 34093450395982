import React from "react"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import Connectors from "./utils/connectors"
import { generateUserSign } from "./services"
/**
 *
 * Custom hook to manage wallet connection and current account.
 * It is a custom wrapper for the useWeb3React hook from web3-react.
 * https://github.com/NoahZinsmeister/web3-react
 *
 */

export default function useWallet() {
  const {
    active,
    account,
    activate,
    deactivate,
    error,
    setError,
    library,
  } = useWeb3React()

  const [walletError, setWalletError] = React.useState(null)

  // Effects

  React.useEffect(() => {
    if (error) {
      if (error instanceof UnsupportedChainIdError) {
        setWalletError({ message: "WALLET_CHAIN_ID_ERROR" })
      } else {
        setWalletError({ message: "WALLET_ERROR" })
      }
    }
  }, [error])

  // Methods

  const resetError = () => {
    setError(null)
    setWalletError(null)
  }

  const removeConnection = () => {
    window.localStorage.removeItem("walletconnect")
  }

  const connect = (wallet = "metamask") => {
    removeConnection()

    switch (wallet) {
      case "metamask":
        activate(Connectors.metamask)
        break

      case "walletconnect":
        activate(Connectors.walletConnect)
        break

      default:
        throw new Error("UNKNOWN_WALLET")
    }
  }

  const disconnect = () => {
    deactivate()
    removeConnection()
  }

  // User generated sign

  function checkWallet() {
    if (!account || !active) {
      throw new Error("WALLET_NOT_CONNECTED")
    }
  }

  
  function getUserSign() {
    checkWallet()
    const signer = library.getSigner()
    return generateUserSign(signer)
  }

  return {
    account,
    active,
    connect,
    disconnect,
    walletError,
    resetWalletError: resetError,
    library,
    //
    getUserSign,
  }
}

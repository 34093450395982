import React from "react"
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"

function getLibrary(provider, connector) {
  return new Web3Provider(provider)
}

const WalletProvider = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>
  )
}

export default WalletProvider

import { soliditySha3 } from "web3-utils"
import { ethers } from "ethers"

import { UserSignatureError } from "./errors"

export async function generateUserSign(signer) {
  try {
    const currentTimestamp = Date.now()
    const msgHash = soliditySha3({ type: "uint256", value: currentTimestamp })
    const messageHashBytes = ethers.utils.arrayify(msgHash)
    const flatSign = await signer.signMessage(messageHashBytes)
    return { flatSign, msgHash }
    //
  } catch (err) {
    if (err.code && err.code === 4001) {
      // code: 4001, message: "MetaMask Message Signature: User denied message signature."
      throw new UserSignatureError("cancel")
    }
    throw new UserSignatureError()
  }
}

import React from "react"
import PropTypes from "prop-types"

import { CartIconButton } from "marketplace/cart"
import Theme from "marketplace/app/styles/theme"
import LogoImg from "marketplace/app/images/icons/logo.svg"

import { Div, Flex, Heading, Nav, Link, Icon } from ".."

const LINKS = [
  { id: "search-page", text: "Search data", url: "/search" },
  { id: "orders-page", text: "Orders", url: "/orders" },
  {
    id: "documentation-page",
    text: "Documentation",
    url: "/docs",
  },
  { id: "cart-page", url: "/cart" },
]


const Header = ({ title }) => {
  return (
    <HeaderWrap>
      <RedLine />
      <NavBar>
        <HeaderTitle title={title} />
        <HeaderLinks links={LINKS} />
      </NavBar>
    </HeaderWrap>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Header

const HeaderWrap = ({ children }) => (
  <header style={{ background: Theme.colors.primary }}>{children}</header>
)

const RedLine = () => (
  <Div
    sx={{
      bg: "secondary",
      height: ["10px", "12px", "14px", "16px"],
    }}
  />
)

const HeaderTitle = ({ title }) => (
  <Flex
  sx={{ flexWrap: "nowrap" }}
  >
    <Link to="/">
      <Heading variant="text.headerTitle">
        {/* <img src="icons/logo.svg" alt="logo" /> */}
        <Icon path={LogoImg} title="logo" sx={{ width: "auto", height: ["29px", "35px", "40px", "40px"]}} /> {title}
      </Heading>
    </Link>
  </Flex>
)

const HeaderLinks = ({ links }) => (
  <Flex
    sx={{
      // flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      display: ["none", "inherit", "inherit", "inherit"]
    }}
  >
    {links.map(link => (
      <Link key={link.id} to={link.url} sx={{ variant: "styles.navLink" }}>
        {link.hasOwnProperty("text") ? link.text : <CartIconButton />}
      </Link>
    ))}
  </Flex>
)

const NavBar = ({ children }) => (
  <Div sx={{ mx: "32px" }}>
    <Nav
      sx={{
        display: "flex",
        flexDirection: ["column","column","column","row"],
        height: ["76px","86px","96px","96px"],
        maxWidth: ["375px", "704px", "882px", "1296px"],
        mx: "auto",
        py: ["20px", "20px", "20px", "unset"],
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Nav>
  </Div>
)

import React from "react"
import isEmpty from "validator/lib/isEmpty"
import scape from "validator/lib/escape"
import stripLow from "validator/lib/stripLow"

import {
  useWallet,
  // useContract
} from "marketplace/web3"

import {
  Flex,
  Text,
  Heading,
  Button,
  ButtonAction,
  ButtonLink,
  ColumLayout,
  AddressBadge,
  ConfirmationModal,
} from "marketplace/app/components"

import CartItem from "./CartItem"
import DatasetEditModal from "./DatasetEditModal"

const REMOVE_CONTENT = {
  title: "Pay attention!",
  description: "Do you really want to remove the dataset from the cart?",
  button: "Remove",
}

const EDIT_CONTENT = {
  title: "Dataset name",
  description: "Give your dataset a custom name",
  button: "Edit",
}

const ADDRESS_BADGE_WRAP_STYLE = {
  mx: "13px",
  my: "24px",
  justifyContent: "flex-end",
  minHeight: "40px",
}

const CART_LIST_WRAP_STYLE = {
  height: "100%",
  maxHeight: "460px",
  width: "100%",
  maxWidth: "680px",
  mx: "auto",
  mt: "40px",
}

function useClickOutside(ref, callback) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

const CartList = props => {
  const {
    cart,
    removeDatasetFromCart,
    datasetRemove,
    setDatasetRemove,
    removing,
    status, // TODO: instead of status, just boolean isIdle ?
    selectedDataset,
    toggleSelected,
    resetSelected,
    startPurchaseHandler,
    updateDatasetNameHandler,
  } = props

  const { active, account } = useWallet()

  const wrapperRef = React.useRef(null)
  useClickOutside(wrapperRef, resetSelected)

  const [isOpenRemove, setIsOpenRemove] = React.useState(false)

  const openRemoveDialog = dataset => {
    setIsOpenRemove(true)
    setDatasetRemove(dataset)
  }

  const closeRemoveDialog = () => {
    setIsOpenRemove(false)
  }

  const removeHandler = () => {
    removeDatasetFromCart(datasetRemove)
    closeRemoveDialog()
  }

  const [isOpenEdit, setIsOpenEdit] = React.useState(false)
  const [datasetEdit, setDatasetEdit] = React.useState(false)
  const [datasetNameEdit, setDatasetNameEdit] = React.useState("")

  const openEditDialog = dataset => {
    setIsOpenEdit(true)
    setDatasetEdit(dataset)
  }

  const closeEditDialog = () => {
    setIsOpenEdit(false)
  }

  const editHandler = e => {
    const newName = e.target.value
    setDatasetNameEdit(newName)
  }

  const submitEditHandler = () => {
    if (datasetEdit || !isEmpty(datasetNameEdit)) {
      // const escapeName = escape(datasetNameEdit)
      // const stripLowName = stripLow(escapeName)
      const stripLowName = stripLow(datasetNameEdit)
      updateDatasetNameHandler(datasetEdit.uuid, stripLowName)
      closeEditDialog()
    }
  }

  // TODO: use wallet balance to àlert user and avoid starting process
  // const { getAccountBalance } = useContract()
  // const [balance, setBalance] = React.useState(null)
  // React.useEffect(() => {
  //   async function getBalance() {
  //     const response = await getAccountBalance()
  //     setBalance(response)
  //   }
  //   getBalance()
  // }, [])

  return (
    <ColumLayout
      sx={{
        minHeight: ["500px", "550px", "600px", "790px"],
        height: "auto",
        pl: "32px",
        pr: ["32px", "32px", "32px", "0px"],
      }}
    >
      <Flex sx={ADDRESS_BADGE_WRAP_STYLE}>
        {active && <AddressBadge address={account} />}
      </Flex>

      <ConfirmationModal
        content={REMOVE_CONTENT}
        isOpen={isOpenRemove}
        closeDialog={closeRemoveDialog}
        submitHandler={removeHandler}
      />

      <DatasetEditModal
        content={EDIT_CONTENT}
        isOpen={isOpenEdit}
        dataset={datasetEdit}
        closeDialog={closeEditDialog}
        inputValue={datasetNameEdit}
        editHandler={editHandler}
        submitHandler={submitEditHandler}
      />

      <Flex column sx={CART_LIST_WRAP_STYLE}>
        <Heading variant="text.pageTitle" sx={{ mb: "50px", mx: "48px" }}>
          Cart
        </Heading>

        <Flex sx={{ maxWidth: "680px" }}>
          <Flex column sx={{ flex: 1 }}>
            {/* Header */}
            <CartListHeader />

            {/* Body */}
            <Flex
              column
              className="blue-scrollbar"
              sx={{
                height: "100%",
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {cart.map((dataset, index) => (
                <CartItem
                  key={dataset.uuid || dataset.id}
                  dataset={dataset}
                  selected={selectedDataset}
                  onSelect={toggleSelected}
                  // onDelete={removeDatasetFromCart}
                  onDelete={openRemoveDialog}
                  removing={removing}
                  datasetRemove={datasetRemove}
                  onEdit={openEditDialog}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        sx={{
          flexDirection: ["column", "row", "row", "row"],
          width: "fit-content",
          justifyContent: "center",
          mx: "auto",
          my: ["56px", "56px", "80px", "56px"],
        }}
      >
        <ButtonLink
          to="/search"
          secondary
          sx={{
            mr: [null, "16px", "16px", "16px"],
            mb: ["16px", "unset", "unset", "unset"],
          }}
        >
          Continue Shopping
        </ButtonLink>

        {active && !selectedDataset && (
          <Button disabled title="First select a dataset">
            <Text variant="text.textSmall">Pay with Metamask</Text>
          </Button>
        )}

        {active && selectedDataset && status === "idle" && (
          <ButtonAction onClick={startPurchaseHandler}>
            Pay with Metamask
          </ButtonAction>
        )}

        {active && selectedDataset && status !== "idle" && (
          <Button disabled>
            <Text variant="text.textSmall">Processing payment...</Text>
          </Button>
        )}
      </Flex>
    </ColumLayout>
  )
}

export default CartList

const CartListHeader = () => (
  <Flex
    sx={{
      justifyContent: "space-between",
      mb: "30px",
      maxWidth: "680px",
      width: "100%",
    }}
  >
    <Flex sx={{ flex: 1 }} />

    <Flex
      sx={{
        flex: 10,
        pb: "15px",
        borderBottom: "1px solid",
        borderBottomColor: "text",
      }}
    >
      <Flex sx={{ flex: 3 }}>
        <Text variant="text.tableHeader">Dataset</Text>
      </Flex>
      {/* <Flex
        sx={{
          flex: 4,
          display: ["none", "inherit", "inherit", "inherit"],

          justifyContent: "center",
        }}
      >
        <Text variant="text.tableHeader">Data Key</Text>
      </Flex> */}
      <Flex sx={{ flex: 3, justifyContent: "flex-end" }}>
        <Text variant="text.tableHeader">Price</Text>
      </Flex>
    </Flex>

    <Flex sx={{ flex: 2 }} />
  </Flex>
)

/** @jsx jsx */
import { jsx } from "theme-ui"
import { keyframes } from "@emotion/react"

// const fadeOut = keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })
// const fadeOut = keyframes`
//   0%   { opacity: 0; }
//   100% { opacity: 1; }
// `
// const opacityAnimation = keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })
const FADE_OUT_ANIMATION = keyframes`
0%   { opacity: 1; }
100% { opacity: 0.6; }
`

const FlexFadeOut = ({ animation, column = false, onClick, sx, children }) => (
  <div
    onClick={onClick}
    sx={{
      display: "flex",
      bg: "background",
      // flex: 1,
      // flexDirection: column && "column",
      // animationName: opacityAnimation,
      // animationDelay: "5s",
      // // animationDuration: "2s",
      // animationDuration: '1s',
      // animationFillMode: 'backwards',
      // // animationFillMode: "backwards",
      // animationFillMode: "forwards",
      animation: animation ? `${FADE_OUT_ANIMATION} 15s 1` : null,
      ...sx,
    }}
  >
    {children}
  </div>
)

export default FlexFadeOut

import React from "react"
import PropTypes from "prop-types"

import { Flex } from ".."

const ColumLayout = ({
  absolute = false,
  overlay = false,
  backgroundColor,
  centered = false,
  sx,
  children,
}) => (
  <Flex
    sx={{
      position: absolute && "absolute",
      zIndex: overlay && 10,
      backgroundColor,
      width: "100%",
      height: "100%",
    }}
  >
    <Flex
      column
      sx={{
        px: "32px",
        width: "100%",
        height: "100%",
        justifyContent: centered && "center",
        alignItems: centered && "center",
        ...sx,
      }}
    >
      {children}
    </Flex>
  </Flex>
)

ColumLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ColumLayout

/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

const LinkIn = ({ to, sx = {}, children }) => {
  return (
    <Link to={to} sx={sx}>
      {children}
    </Link>
  )
}

export default LinkIn

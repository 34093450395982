/** @jsx jsx */
import { jsx } from "theme-ui"

const Flex = ({
  column = false,
  className,
  title,
  onClick = () => {},
  sx,
  children,
}) => (
  <div
    className={className}
    title={title}
    onClick={onClick}
    sx={{
      display: "flex",
      // flex: 1,
      flexDirection: column && "column",
      ...sx,
    }}
  >
    {children}
  </div>
)

export default Flex

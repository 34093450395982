import getContract from "./get-contract"

export default function createTransaction({
  contractName = "marketplace",
  methodName = "send",
  signer,
}) {
  const contract = getContract({ contractName, signer })
  const preflight = contract.callStatic[methodName]
  const send = contract[methodName]

  return async ({
    inputs = [],
    params,
    onTransactionHash,
    onTransactionConfirmation,
    onTransactionError,
  }) => {
    try {
      await preflight(...inputs, { ...params })
      const txResponse = await send(...inputs, { ...params })

      if (onTransactionHash && txResponse.hash) {
        onTransactionHash(txResponse.hash)
      }

      if (onTransactionConfirmation && typeof txResponse.wait === "function") {
        txResponse
          .wait(1)
          .then(txReceipt => onTransactionConfirmation(txReceipt))
      }

      return txResponse
    } catch (error) {
      onTransactionError(error.error || error)
    }
  }
}

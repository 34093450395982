import React from "react"
import PropTypes from "prop-types"

import { Flex } from ".."
import SEO from "./SEO"

const Page = ({ seo, sx, children }) => (
  <React.Fragment>
    <SEO title={seo} />
    <Flex
      sx={{
        flex: 1,
        // height: "auto",
        minHeight: ["auto","auto","auto", "790px"],
        // height: ["auto", "auto", "auto", "790px"],

      }}
    >
      <Flex
        sx={{
          flex: 1,
          maxWidth: "1440px",
          mx: "auto",
          flexDirection: ["column", "column", "column", "row"],
          // bg: "muted",
          ...sx,
        }}
      >
        {children}
      </Flex>
    </Flex>
  </React.Fragment>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page

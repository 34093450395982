import React from "react"

import { getOrdersByAddress, getDatasetDownloadUrl, deletePendingOrder } from "../services"
import { useWallet } from "marketplace/web3"

type ADDRESS = string

function sortRecentFirst(_orders) {
  return _orders.sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.summaryDate) - new Date(a.summaryDate);
  });
}

export default function useOrders() {
  const { account, getUserSign } = useWallet()

  const [orders, setOrders] = React.useState([])
  const [fetching, setFetching] = React.useState(false)
  const [removing, setRemoving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!account) return
    fetchOrders(account)
  }, [account])

  const fetchOrders = async (account: ADDRESS) => {
    setFetching(true)

    try {
      const response = await getOrdersByAddress(account)
      setOrders(sortRecentFirst(response))
    } catch (err) {
      setError(err)
    } finally {
      setFetching(false)
    }

  }

  const getDownloadUrl = async (datasetId) => {
    try {
      // userSign = { flatSign, msgHash }
      const userSign = await getUserSign()
      const downloadUrl = await getDatasetDownloadUrl({ userSign, datasetId })
      // downloadUrl = {id, internal_link}
      updateDatasetUrl({ datasetId, downloadUrl: downloadUrl.internal_link })
    } catch (err) {
      setError(err)
    }
  }

  const updateDatasetUrl = ({ datasetId, downloadUrl }) => {
    const ordersUpdated = orders.map(dataset => {
      if (dataset.id !== datasetId) return dataset
      return {
        ...dataset,
        downloadUrl
      }
    })
    setOrders(ordersUpdated)
  }

  const removePendingOrder =  async (datasetId) => {
    setRemoving(true)
    try {
      // userSign = { flatSign, msgHash }
      const userSign = await getUserSign()
      return await deletePendingOrder({ userSign, datasetId })
    } catch (err) {
      setError(err)
    } finally {
      setRemoving(false)
    }
  }

  return {
    orders: orders.filter(order => order.status === "available" || order.status === "pending-queued"),
    pendingOrders: orders.filter(order => order.status === "pending-order"),
    fetching,
    error,
    getDownloadUrl,
    removePendingOrder,
    removing
  }
}
import React from "react"

import { Button, Text, Flex } from "marketplace/app/components"
import MetamaskLogo from "marketplace/app/images/icons/metamask.svg"

const MetamaskButton = ({ clickHandler, dark, sx }) => (
  <Button
    variant={dark ? "buttons.primary" : "buttons.metamask"}
    onClick={clickHandler}
    sx={{
      display: ["none", "none", "unset", "unset"],
      // width: smaller ? "220px" : "310px",
      width: "250px",
      // backgroundColor: disabled ? "lightgray" : "black",
      justifyContent: "center",
      ...sx
    }}
    // disabled={disabled}
  >
    <Flex
      sx={{
        width: "90%",
        justifyContent: "space-between",
        mx: "auto",
        alignItems: "center",
      }}
    >
      <Flex
        sx={{
          minWidth: "40px",
          justifyContent: "center",
        }}
      >
        <img src={MetamaskLogo} alt="Metamask" style={{ height: "25px", filter: dark && "invert(1)" }} />
      </Flex>
      <Flex
        sx={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Text variant="text.textSmall">Connect to Metamask</Text>
      </Flex>
    </Flex>
  </Button>
)

export default MetamaskButton

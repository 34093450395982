/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"
import PropTypes from "prop-types"

const ContentPanel = ({ sx, children }) => (
  <section
    sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      ...sx,
    }}
  >
    {children}
  </section>
)

ContentPanel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentPanel

/**
 *
 */
export class AllowanceTokenError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AllowanceTokenError)
    }

    this.name = "AllowanceTokenError"
    this.date = new Date()

    this.type = type

    this.message =
      "There has been an error checking the permission for the approval of the purchase in the GEO Token contract."
  }
}

/**
 *
 */
 export class BalanceTokenError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BalanceTokenError)
    }

    this.name = "BalanceTokenError"
    this.date = new Date()

    this.type = type

    this.message =
      "There has been an error getting the account balance in the GEO token contract."
  }
}

/**
 *
 */
export class ApproveTokenError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApproveTokenError)
    }

    this.name = "ApproveTokenError"
    this.date = new Date()

    this.type = type

    if (type === "cancel") {
      this.message =
        "You have just canceled the approval required by the GEO Token contract. This step is necessary for the Marketplace contract to withdraw the corresponding funds from the GEO Token contract."
    } else {
      this.message =
        "There has been an error in approving the purchase in the GEO Token contract."
    }
  }
}

/**
 *
 */
export class PurchaseTxError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PurchaseTxError)
    }

    this.name = "PurchaseTxError"
    this.date = new Date()

    this.type = type

    if (type === "cancel") {
      this.message =
        "You have canceled the final step to complete the dataset purchase transaction."
    } else {
      this.message =
        "There was an error performing the dataset purchase transaction."
    }
  }
}

import React from "react"

import { Button, Text } from ".."

const SECONDARY_TOKEN = "buttons.secondary"

const ButtonAction = ({ secondary, variant, onClick, sx, children }) => (
  <Button
    variant={secondary ? SECONDARY_TOKEN : variant}
    onClick={onClick}
    sx={sx}
  >
    <Text variant="text.textSmall">{children}</Text>
  </Button>
)

export default ButtonAction

import React from "react"

import {
  ModalDialog,
  Heading,
  Text,
  ButtonAction,
  Spinner,
  Flex,
} from "marketplace/app/components"

const CONTENT = {
  title: "Purchasing process",
  button: {
    ok: "OK",
    cancel: "Cancel",
    retry: "Retry",
  },
}

const PurchaseModal = ({
  status,
  error,
  isOpen,
  resetHandler,
  retryHandler,
  successHandler,
}) => {
  // TODO: refactor this shit
  const getFeedback = () => {
    let body = { step: "", text: "" }

    if (status === "started") {
      body.step = "1 / 4"
      body.text =
        "First, you need to generate a personal signature with MetaMask."
    }
    if (status === "user-signed") {
      body.step = "2 / 4"
      body.text =
        "Second, a custom signature is being requested for the dataset purchase."
    }
    if (status === "purchase-signed") {
      body.step = "3 / 4"
      body.text =
        "Third, you must approve the withdrawal of GEOs in the token contract by the marketplace."
    }
    if (status === "approved") {
      body.step = "4 / 4"
      body.text =
        "Finally, the dataset purchase transaction sent to the marketplace contract."
    }
    if (status === "purchased") {
      body.step = "🎉"
      body.text = "Your purchase has been completed successfully!"
    }
    if (status === "stopped") {
      body.step = "❌"
      body.text = error.message || "Something has gone wrong..."
    }
    if (status === "error") {
      body.step = "🔥" // "⚠️"
      body.text = error.message || "There has been an error!"
    }
    return body
  }

  const body = getFeedback()

  return (
    <ModalDialog show={isOpen} withOnClose={false} ariaLabel={CONTENT.title}>
      <Flex
        column
        sx={{
          minHeight: "370px",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Heading variant="text.previewTitle" sx={{ mb: "32px" }}>
          {CONTENT.title}
        </Heading>

        <Text
          variant="text.textBody"
          sx={{
            fontFamily: "heading",
            maxWidth: "550px",
            mb: "40px",
            mx: "auto",
          }}
        >
          {body.step}
        </Text>
        <Text
          variant="text.textBody"
          sx={{ maxWidth: "550px", mb: "40px", mx: "auto" }}
        >
          {body.text}
        </Text>

        <Flex
          sx={{
            justifyContent: "center",
            // my: "56px",
          }}
        >
          {status === "stopped" && (
            <>
              <ButtonAction
                secondary
                onClick={resetHandler}
                sx={{ mr: "16px" }}
              >
                {CONTENT.button.cancel}
              </ButtonAction>
              <ButtonAction onClick={retryHandler}>
                {CONTENT.button.retry}
              </ButtonAction>
            </>
          )}

          {status === "purchased" && (
            <ButtonAction onClick={successHandler}>
              {CONTENT.button.ok}
            </ButtonAction>
          )}

          {status === "error" && (
            <ButtonAction onClick={resetHandler}>
              {CONTENT.button.ok}
            </ButtonAction>
          )}

          {status !== "stopped" &&
            status !== "error" &&
            status !== "purchased" && <Spinner />}
        </Flex>
      </Flex>
    </ModalDialog>
  )
}

export default PurchaseModal

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { WalletProvider } from "marketplace/web3"
import { CartProvider } from "marketplace/cart"
import { FullViewport, Header, MainContent, Footer } from "./app/components"


const App = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          company
          companyUrl
        }
      }
    }
  `)

  const { title, company, companyUrl } = data.site.siteMetadata

  return (
    <WalletProvider>
      <CartProvider>
        <FullViewport>
          <Header title={title} />
          <MainContent>{children}</MainContent>
          <Footer company={company} companyUrl={companyUrl} />
        </FullViewport>
      </CartProvider>
    </WalletProvider>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App

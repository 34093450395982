/** @jsx jsx */
import { jsx, Flex, Text, Box } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { useIntl, Link } from "gatsby-plugin-intl"
// import LazyLoad from "react-lazyload"

import SocialLinks from "../elements/social-links"
import logo from "../../images/logo-white.svg"
import LINKS from "../../content/links"

const Footer = ({ siteTitle, companyName }) => {
  const intl = useIntl()

  return (
    <footer>
      {/* Navbar footer */}
      <div
        sx={{
          color: "background",
          bg: "text",
          width: "100%",
        }}
      >
        <div sx={{ mx: "32px" }}>
          <Flex
            sx={{
              flexDirection: ["column", "column", "column", "row"],
              // maxWidth: ["311px", "311px", "1200px", "1200px"],
              maxWidth: ["375px", "704px", "882px", "1296px"],
              width: "100%",
              mx: "auto",
              alignItems: "center",
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", "column", "column", "row"],
                // maxWidth: ["375px", "704px", "882px", "1264px"],
                // maxWidth: ["319px", "319px", "1200px", "1200px"],
                width: "100%",
                mx: "auto",
                pt: ["60px", "60px", "60px", "unset"],
                pb: ["60px", "60px", "60px", "unset"],
                // px: "32px",
                flexWrap: "nowrap",
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  width: [null, null, null, "500px"],
                  justifyContent: "start",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    mt: [null, null, null, "80px"],
                    mb: ["20px", "20px", "30px", "40px"],
                  }}
                >
                  <img alt={siteTitle} src={logo} />
                </Box>
                <Text
                  variant="textSmallToSmaller"
                  sx={{
                    width: [null, null, null, "460px"],
                    mb: ["40px", "40px", "40px", "80px"],
                  }}
                >
                  {intl.formatMessage({
                    id: "layout.footer.company_description",
                  })}
                </Text>
              </Flex>

              <Flex
                sx={{
                  ml: [null, null, null, "40px"],
                  flex: "1 1 auto",
                  justifyContent: "space-between",
                  flexWrap: ["wrap", "wrap", "nowrap", "nowrap"],
                }}
              >
                {LINKS.map((categoryLink, index) => {
                  if (index === 0) return
                  if (categoryLink.links) {
                    return (
                      <Flex
                        key={`footer-linkgroup-${categoryLink.id}`}
                        sx={{
                          flexDirection: "column",
                          width: ["150px", "150px", "unset", "unset"],
                          height: "100%",
                          pb: ["20px", "20px", "60px", "60px"],
                          // flex: 1,
                        }}
                      >
                        <Text
                          variant="text.textBody"
                          sx={{
                            mt: ["20px", "20px", "0px", "92px"],
                            mb: ["20px", "20px", "20px", "40px"],
                          }}
                        >
                          {intl.formatMessage({ id: categoryLink.title })}
                        </Text>
                        {categoryLink.links.map(link => (
                          <a
                            key={`footer-link-${link.id}`}
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Text
                              variant="text.textSmallToSmaller"
                              sx={{
                                mb: ["8px", "8px", "16px", "16px"],
                                color: "background",
                              }}
                            >
                              {intl.formatMessage({ id: link.title })}
                            </Text>
                          </a>
                        ))}
                      </Flex>
                    )
                  }
                })}
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>

      {/* Base Footer */}
      <div
        sx={{
          color: "background",
          bg: "primary",
        }}
      >
        <div sx={{ mx: "32px" }}>
          <Flex
            sx={{
              flexDirection: ["column", "column", "row", "row"],
              // maxWidth: ["311px", "311px", "1200px", "1200px"],
              maxWidth: ["375px", "704px", "882px", "1296px"],
              width: "100%",
              mx: "auto",
              alignItems: "center",
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", "column", "row", "row"],
                // maxWidth: "1200px",
                // maxWidth: ["319px", "319px", "1200px", "1200px"],
                // width: "100%",
                flex: 1,
                mx: "auto",
                justifyContent: [
                  "center",
                  "center",
                  "space-between",
                  "space-between",
                ],
                py: ["30px", "30px", "25px", "25px"],
                // px: "32px",
              }}
            >
              <Flex
                sx={{
                  flexDirection: "column",
                  // width: [null, null, "500px", "500px"],
                  // maxWidth: [null, null, "55%", "50%"],
                  justifyContent: "start",
                  alignSelf: ["center", "center", "center", "center"],
                }}
              >
                <Text
                  variant="textSmallToSmaller"
                  sx={{
                    lineHeight: "18px",
                    mb: ["24px", "24px", "0px", "0px"],
                  }}
                >
                  {intl.formatMessage({ id: "layout.footer.copyright" })}{" "}
                  {new Date().getFullYear()} {companyName}{" "}
                  {intl.formatMessage({ id: "layout.footer.rights" })}
                </Text>
              </Flex>

              <Flex
                sx={{
                  alignSelf: ["center", "center", "center", "center"],
                  // width: ["100%", "unset", "unset", "unset"],
                  flex: 1,
                  // maxWidth: [null, null, "45%", "50%"],
                  // maxWidth: "50%",
                  justifyContent: ["center", "center", "flex-end", "flex-end"],
                  whiteSpace: "nowrap",
                  flexWrap: ["wrap", "wrap", "unset", "unset"],
                }}
              >
                {/* Product Hunt badge */}
                {/* <LazyLoad once offset={600}> */}
                <a
                  href="https://www.producthunt.com/posts/geodb-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-geodb-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    width: ["97px", "97px", "116px", "116px"],
                    height: ["21px", "21px", "25px", "25px"],
                    mr: ["10px", "32px", "32px", "24px"],
                    ml: ["10px", "unset", "unset", "unset"],
                    mb: ["14px", "14px", "unset", "unset"],
                  }}
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=207441&theme=dark"
                    alt="Product Hunt"
                    sx={{
                      width: ["97px", "97px", "116px", "116px"],
                      height: ["21px", "21px", "25px", "25px"],
                      // verticalAlign: "middle",
                      filter: "brightness(1) invert(1)",
                    }}
                    // width="116px"
                    // height="25px"
                  />
                </a>
                {/* </LazyLoad> */}
                <SocialLinks invertColor containerName="footer-section" />
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  companyName: PropTypes.string,
  companyUrl: PropTypes.string,
}

Footer.defaultProps = {
  companyName: ``,
  companyUrl: ``,
}

export default Footer

export default [
  {
    id: "medium",
    name: "Medium",
    // img: require("../../images/social/medium.svg"),
    image: "medium.png",
    url: "https://geodb.medium.com/",
  },
  {
    id: "twitter",
    name: "Twitter",
    // img: require("../../images/social/twitter.svg"),
    image: "twitter.png",
    url: "https://twitter.com/GeoDataBlock",
  },
  {
    id: "telegram",
    name: "Telegram",
    // img: require("../../images/social/telegram.svg"),
    image: "telegram.png",
    url: "https://t.me/GeoDataBlock",
  },
  {
    id: "linkedin",
    name: "LinkedIn",
    // img: require("../../images/social/linkedin.svg"),
    image: "linkedin.png",
    url: "https://www.linkedin.com/company/geodb/",
  },
  {
    id: "facebook",
    name: "Facebook",
    // img: require("../../images/social/facebook.svg"),
    image: "facebook.png",
    url: "https://www.facebook.com/GeoDataBlock/",
  },
  {
    id: "reddit",
    name: "Reddit",
    // img: require("../../images/social/facebook.svg"),
    image: "reddit.png",
    url: "https://www.reddit.com/r/GeoDB/",
  },
  {
    id: "instagram",
    name: "Instagram",
    // img: require("../../images/social/facebook.svg"),
    image: "instagram.png",
    url: "https://www.instagram.com/geodb_ecosystem",
  },
  {
    id: "discord",
    name: "Discord",
    // img: require("../../images/social/facebook.svg"),
    image: "discord.png",
    url: "https://discord.gg/KWudZCCUcm",
  },
]

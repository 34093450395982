/** @jsx jsx */
import { jsx } from "theme-ui"

const Input = ({ type, inputName, placeholder, value, onChange, sx }) => (
  <input
    type={type}
    name={inputName}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    sx={{ ...sx }}
  />
)

export default Input


{/* <input
      {...props}
      placeholder={placeholder}
      value={props.value || ""}
      onFocus={focusHandler}
      onBlur={blurHandler}
      sx={{
        width: "auto",
        outline: "none",
        "&::placeholder": {
          opacity: 1,
          color: "text",
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    /> */}
// export default {
module.exports = {
  breakpoints: ["30em", "48em", "64em"], // 480px, 768px, 1024px
  colors: {
    text: "#000",
    // text: "#2B292D",
    background: "#fff",
    primary: "#0048ff",
    secondary: "#ff607b",
    muted: "#d3e9ef",
    //
    disabled: "#c5c5c5",
    orange: "#FFA141",
    redLightColor: "#FFD9E0",
    greenColor: "#00DDC2",
    greenLightColor: "#BEF3ED",
  },
  fonts: {
    heading: "SharpSansNo1-Bold",
    body: "SharpSansNo1-Semibold",
    slim: "SharpSansNo1-Medium",
    bold: "SharpSansNo1-Bold",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  text: {
    headerTitle: {
      fontFamily: "heading",
      color: "muted",
      fontSize: ["22px","28px","33px","33px"],
      lineHeight: ["24px","28px","33px","33px"],
    },
    homeTitle: {
      fontFamily: "heading",
      fontSize: ["40px","60px","80px","104px"],
      letterSpacing: "1.3px",
      lineHeight: ["48px","68px","88px","112px"],
    },
    homeSubtitle: {
      fontFamily: "heading",
      fontSize: ["28px","32px","36px","40px"],
      // letterSpacing: "px",
      lineHeight: ["36px","40px","44px","48px"],
    },
    pageTitle: {
      fontFamily: "heading",
      fontSize: ["24px","38px","52px","72px"],
      letterSpacing: "0.9px",
      lineHeight: ["32px","46px","60px","80px"],
      mb: "40px",
    },
    loadingTitle: {
      fontFamily: "heading",
      color: "primary",
      fontSize: "64px",
      lineHeight: "72px",
      mb: "40px",
    },
    loadingSubtitle: {
      fontFamily: "body",
      fontSize: "48px",
      lineHeight: "34px",
    },
    previewTitle: {
      fontFamily: "heading",
      fontSize: ["36px","40px","44px","48px"],
      lineHeight: ["44px","48px","52px","56px"],
      letterSpacing: "0.6px",
    },
    smallTitle: {
      fontFamily: "heading",
      fontSize: "28px",
      lineHeight: "36px",
    },
    textBody: {
      fontFamily: "body",
      // lineHeight: "heading",
      fontSize: ["20px", "24px", "22px", "24px"],
      lineHeight: ["24px", "30px", "28px", "34px"],
      // letterSpacing: ["0.25px", "0.25px", "unset", "unset"],
    },
    textSummary: {
      fontFamily: "body",
      // lineHeight: "heading",
      fontSize: ["16px", "18px", "20px", "22px"],
      lineHeight: ["28px", "30px", "32px", "34px"],
      // letterSpacing: ["0.25px", "0.25px", "unset", "unset"],
    },
    textCartItem: {
      fontFamily: "body",
      // lineHeight: "heading",
      fontSize: ["18px", "20px", "20px", "22px"],
      lineHeight: ["30px", "32px", "32px", "34px"],
      // letterSpacing: ["0.25px", "0.25px", "unset", "unset"],
    },
    textSmall: {
      fontFamily: "body",
      // lineHeight: "heading",
      fontSize: "16px",
      // letterSpacing: "0.5px",
      lineHeight: "24px",
    },
    textSmallToSmaller: {
      fontFamily: "body",
      // lineHeight: "heading",
      fontSize: ["14px", "14px", "16px", "16px"],
      // letterSpacing: "0.5px",
      lineHeight: ["24px", "24px", "26px", "26px"],
    },
    textInput: {
      fontFamily: "heading",
      // lineHeight: "heading",
      fontSize: ["20px", "24px", "22px", "24px"],
      lineHeight: ["24px", "28px", "24px", "28px"],
    },
    dateRange: {
      fontFamily: "heading",
      // lineHeight: "heading",
      fontSize: "16px",
      lineHeight: ["24px", "28px", "24px", "28px"],
    },
    tableHeader: {
      fontFamily: "heading",
      fontSize: ["24px","24px","28px","32px"],
      lineHeight: ["29px","29px","33px","37px"],
    },
  },
  buttons: {
    primary: {
      border: "none",
      color: "background",
      bg: "text",
      minWidth: "200px",
      width: "fit-content",
      height: "56px",
      borderRadius: "28px 0px",
      cursor: "pointer",
      outline: "none",
      // "&:hover": {
      //   bg: "text",
      // },
      "&:focus": {
        outline: "none",
        boxShadow: "none",
      },
    },
    secondary: {
      variant: "buttons.primary",
      bg: "primary",
    },
    disabled: {
      variant: "buttons.primary",
      bg: "disabled",
      color: "text",
      cursor: "not-allowed",
    },
    metamask: {
      variant: "buttons.primary",
      bg: "orange",
      color: "text",
    },
  },
  badges: {
    circle: {
      // fontFamily: "heading",
      bg: "background",
      color: "text",
      borderRadius: "9999px",
      px: "4px",
      whiteSpace: "no-wrap",
      verticalAlign: "baseline",
      height: "16px",
      minWidth: "16px",
      textAlign: "center",
    },
  },
  // other elements
  styles: {
    navLink: {
      color: "background",
      ml: "40px",
      // "&:hover": {
      //   fontFamily: "body",
      // },
    },
  },
}

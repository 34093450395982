import React from "react"

import { Flex } from ".."

const HomeView = ({ children }) => (
  <Flex
    sx={{
      width: "100%",
      height: "fit-content",
      minHeight: "790px",
      // minHeight: [null, null, "500px", "790px"],
    }}
  >
    <Flex sx={{ px: "32px", mx: "auto" }}>
      <Flex
        sx={{
          // flexDirection: "column",
          // justifyContent: "center",
          // marginLeft: "auto",
          // marginRight: "auto",
          // marginBottom: "100px",

          //     height: "auto",
          //     minHeight: [null, null, "500px", "790px"],
          width: "100%",
          maxWidth: ["375px", "704px", "882px", "1296px"],
          mx: "auto",
        }}
      >
        <Flex
          column
          sx={{
            maxWidth: "1043px",
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            mt: "160px",
            mx: "auto",
            // justifyContent: "flex-end",
            mb: ["160px","140px","120px","50px"],
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)

export default HomeView

import React from "react"
import PropTypes from "prop-types"

import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"

import CloseImg from "marketplace/app/images/icons/modal-close.svg"

import { Flex, ButtonIcon } from ".."

const ModalDialog = ({
  show,
  onClose,
  withOnClose = true,
  ariaLabel,
  children,
}) => (
  <DialogOverlay
    style={{
      zIndex: 12,
      background: "hsla(0, 100%, 100%, 0.7)",
    }}
    isOpen={show}
    onDismiss={withOnClose ? onClose : () => {}}
  >
    <DialogContent
      aria-label={ariaLabel}
      style={{
        width: "660px",
        // height: "370px",
        zIndex: 12,
        boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
        borderRadius: "24px",
        textAlign: "center",
        padding: "24px",
        paddingBottom: "40px",
      }}
    >
      {/* <Dialog isOpen={isOpen} onDismiss={closeDialog}> */}
      {/* <button className="close-button" onClick={closeDialog}>
            <span aria-hidden>×</span>
          </button> */}
      {withOnClose && (
        <Flex sx={{ justifyContent: "flex-end" }}>
          <ButtonIcon
            src={CloseImg}
            onClick={onClose}
            alt="Close"
            title="Close"
            sx={{ p: "2px" }}
          />
        </Flex>
      )}
      {children}
      {/* </Dialog> */}
    </DialogContent>
  </DialogOverlay>
)

ModalDialog.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalDialog

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatPrice(x) {
  const numParts = x.split(/\.(?=[^\.]+$)/)
  return [
    numberWithCommas(numParts[0]),
    numParts[1].substring(0,2)
  ]
}

import React from "react"

import { useWallet, useContract } from "marketplace/web3"

import { generatePurchaseSign } from "../services"
import useCart from "./useCart"
import { PurchaseTokenBalanceError } from "../errors"
import { getEtherStringFromWeiString } from "marketplace/web3/contracts/utils/unit-conversion"


const INITIAL_STATE = {
  selectedDataset: null,
  status: "idle",
  error: null
}

export const checkoutReducer = (state, action) => {

  if (action.type === "ADD_DATASET_TO_SELECTION") {
    return {
      ...state,
      selectedDataset: action.payload.dataset
    }
  }

  if (action.type === "REMOVE_DATASET_FROM_SELECTION") {
    return {
      ...state, // or => status = "idle" ?
      selectedDataset: null
    }
  }

  if (action.type === "UPDATE_DATASET_PROCESSING_PURCHASE") {
    return {
      ...state,
      status: "started",
    }
  }

  if (action.type === "UPDATE_DATASET_USER_SIGN") {
    const { userSign } = action.payload
    const updatedDataset = { ...state.selectedDataset }
    updatedDataset.userSign = userSign

    return {
      selectedDataset: updatedDataset,
      status: "user-signed",
    }
  }

  if (action.type === "UPDATE_DATASET_PURCHASE_SIGN") {
    const { grossPrice, purchaseSign, datasetId } = action.payload

    const updatedDataset = { ...state.selectedDataset }
    updatedDataset.grossPrice = grossPrice
    updatedDataset.purchaseSign = purchaseSign
    updatedDataset.id = datasetId

    return {
      selectedDataset: updatedDataset,
      status: "purchase-signed",
    }
  }

  if (action.type === "UPDATE_DATASET_PURCHASE_APPROVE") {
    const updatedDataset = { ...state.selectedDataset }
    updatedDataset.approved = true

    return {
      selectedDataset: updatedDataset,
      status: "approved",
    }
  }

  if (action.type === "UPDATE_DATASET_PURCHASE_SUCCESS") {
    return {
      ...state,
      status: "purchased",
    }
  }

  if (action.type === "RESET_DATASET_PURCHASE") {
    return {
      status: "idle",
      selectedDataset: null
    }
  }

  if (action.type === "STOP_DATASET_PURCHASE") {
    return {
      ...state,
      status: "stopped",
      error: action.payload.error
    }
  }

  if (action.type === "ERROR_DATASET_PURCHASE") {
    return {
      ...state,
      status: "error",
      error: action.payload.error
    }
  }

  if (action.type === "RETRY_UPDATE_DATASET_PURCHASE") {
    return {
      ...state,
      status: action.payload.status,
      error: null
    }
  }

  throw new Error(
    `Checkout Reducer Error: The indicated action could not be resolved: ${action.type}`
  )

}

export default function useCheckout() {
  const [state, dispatch] = React.useReducer(checkoutReducer, INITIAL_STATE)
  const { status, selectedDataset, error } = state

  const { purchaseSuccessCartHandler } = useCart()

  const [processing, setProcessing] = React.useState(false)

  const { getUserSign } = useWallet()
  const {
    getAccountBalance,
    checkAlreadyApproved, requestPurchaseApproval, requestPurchaseDataset } = useContract()

  /**
   * Actions
   */

  const selectDataset = dataset => {
    dispatch({
      type: "ADD_DATASET_TO_SELECTION",
      payload: { dataset },
    })
  }

  const deselectDataset = () => {
    dispatch({
      type: "REMOVE_DATASET_FROM_SELECTION"
    })
  }

  const processDatasetPurchase = () => {
    dispatch({
      type: "UPDATE_DATASET_PROCESSING_PURCHASE",
    })
  }

  const updateDatasetUserSign = ({ userSign }) => {
    dispatch({
      type: "UPDATE_DATASET_USER_SIGN",
      payload: { userSign },
    })
  }

  const updateDatasetPurchaseSign = ({
    grossPrice,
    purchaseSign,
    datasetId,
  }) => {
    dispatch({
      type: "UPDATE_DATASET_PURCHASE_SIGN",
      payload: { grossPrice, purchaseSign, datasetId },
    })
  }

  const updateDatasetPurchaseApprove = () => {
    dispatch({
      type: "UPDATE_DATASET_PURCHASE_APPROVE"
    })
  }

  const updateDatasetPurchaseSuccess = () => {
    dispatch({
      type: "UPDATE_DATASET_PURCHASE_SUCCESS",
    })
  }

  const resetSuccessPurchase = () => {
    dispatch({
      type: "RESET_DATASET_PURCHASE",
    })
  }

  const stopDatasetPurchase = err => {
    dispatch({
      type: "STOP_DATASET_PURCHASE",
      payload: { error: err }
    })
  }

  const errorDatasetPurchase = err => {
    dispatch({
      type: "ERROR_DATASET_PURCHASE",
      payload: { error: err }
    })
  }

  const resetDatasetPurchase = () => {
    dispatch({
      type: "RESET_DATASET_PURCHASE",
    })
  }

  const retryUpdateDatasetPurchase = status => {
    dispatch({
      type: "RETRY_UPDATE_DATASET_PURCHASE",
      payload: { status }
    })
  }


  /**
   * Methods
   */

  const toggleSelected = React.useCallback(dataset => {
    if (selectedDataset && selectedDataset.uuid === dataset.uuid) {
      resetSelected()
    } else {
      selectDataset(dataset)
    }
  }, [selectDataset])

  const resetSelected = () => {
    deselectDataset()
  }

  // Purchase steps
  // 1. Generate a personal signature with the wallet (MetaMask)
  // 2. Request a purchase signature, for consultation parameters and linked to the user's personal signature.
  // 3. Request the Token contract to allow the Marketplace contract to take the requested tokens
  // 4. Make the purchase transaction


  const checkBalance = async () => {
    const balance = await getAccountBalance()
    const price = selectedDataset.grossPrice || selectedDataset.price && getEtherStringFromWeiString(selectedDataset.price)
    return balance > price
  }

  const startPurchaseHandler = async () => {
    setProcessing(true)

    try {
      const hasBalance = await checkBalance()
      if (!hasBalance) throw new PurchaseTokenBalanceError()

      startPurchase()

    } catch (err) {
      stopPurchaseHandler(err)
    }
  }

  const stopPurchaseHandler = err => {
    // TODO: update dataset in global cart ?
    if (err.type === "cancel") {
      stopDatasetPurchase(err)
    } else {
      // TODO: logger
      errorDatasetPurchase(err)
    }
  }

  const resetPurchase = () => {
    setProcessing(false)
    resetDatasetPurchase()
  }

  const retryPurchase = () => {
    if (!selectedDataset.userSign) {
      retryUpdateDatasetPurchase("started")
    } else if (selectedDataset.userSign && !selectedDataset.purchaseSign && !selectedDataset.id) {
      retryUpdateDatasetPurchase("user-signed")
    } else if (selectedDataset.purchaseSign && !selectedDataset.approved) {
      retryUpdateDatasetPurchase("purchase-signed")
    } else if (selectedDataset.purchaseSign && selectedDataset.approved) {
      retryUpdateDatasetPurchase("approved")
    }
  }

  // Hook listening status events for the main purchase flow
  React.useEffect(() => {
    checkPurchaseStatus()
  }, [status])

  const checkPurchaseStatus = () => {
    switch (status) {
      case "started":
        goUserSign()
        break;

      case "user-signed":
        goPurchaseSign()
        break;

      case "purchase-signed":
        goPurchaseApprove()
        break;

      case "approved":
        goPurchase()
        break;

      default:
        break;
    }
  }

  const startPurchase = () => {
    if (status === "idle") {
      processDatasetPurchase()
    }
  }

  const goUserSign = async () => {
    try {
      const userSign = await getUserSign()
      updateDatasetUserSign({ userSign })
    } catch (err) {
      // type: cancel | error
      stopPurchaseHandler(err)
    }
  }

  const goPurchaseSign = async () => {
    // TODO: check already generated first
    // if (!selectedDataset.userSign || selectedDataset.userSign && selectedDataset.purchaseSign) {
    //   retryPurchase()
    // } else ...

    try {
      const purchaseSignResponse = await generatePurchaseSign({
        name: selectedDataset.name,
        userSign: selectedDataset.userSign,
        datasetParams: selectedDataset.params
      })

      const { gross_price, purchase_sign, dataset_id } = purchaseSignResponse

      // TODO: update global dataset too ?

      updateDatasetPurchaseSign({
        datasetId: dataset_id,
        grossPrice: gross_price,
        purchaseSign: {
          v: ["0x" + purchase_sign[0].v, "0x" + purchase_sign[1].v],
          r: ["0x" + purchase_sign[0].r, "0x" + purchase_sign[1].r],
          s: ["0x" + purchase_sign[0].s, "0x" + purchase_sign[1].s],
        }
      })
    } catch (err) {
      stopPurchaseHandler(err)
    }
  }

  // Approve (GeoToken contract)

  const goPurchaseApprove = async () => {
    try {
      const { grossPrice } = selectedDataset
      const allowed = await checkAlreadyApproved()
      if (!allowed || allowed === "0" || allowed !== grossPrice) {
        requestPurchaseApproval({
          amount: grossPrice,
          onSuccess: approveSuccessCallback,
          onError: approveErrorCallback,
        })
      } else {
        updateDatasetPurchaseApprove()
      }
    } catch (err) {
      // here we catch allowance error only
      // tx approve error is handled with the callback
      stopPurchaseHandler(err)
    }

  }

  // TODO: receipt feedback ?
  const approveSuccessCallback = txReceipt => {
    updateDatasetPurchaseApprove()
  }

  const approveErrorCallback = err => {
    stopPurchaseHandler(err)
  }

  // Purchase (Marketplace contract)

  const goPurchase = () => {
    const { id, purchaseSign, grossPrice } = selectedDataset
    requestPurchaseDataset({
      datasetId: id,
      price: grossPrice,
      purchaseSign,
      onSuccess: purchaseSuccessCallback,
      onError: purchaseErrorCallback,
    })
  }

  // TODO: receipt feedback ?
  const purchaseSuccessCallback = txReceipt => {
    updateDatasetPurchaseSuccess()
  }

  const purchaseErrorCallback = (err) => {
    stopPurchaseHandler(err)
  }

  const successHandler = () => {
    setProcessing(false)
    resetSuccessPurchase()
    purchaseSuccessCartHandler(selectedDataset.uuid)
  }


  return {
    // reducer state
    status,
    error,
    selectedDataset,
    // Hook state
    processing,
    // Hook methods
    toggleSelected,
    resetSelected,
    startPurchaseHandler,
    resetPurchase,
    retryPurchase,
    successHandler
  }
}

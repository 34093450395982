/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"
import PropTypes from "prop-types"

const MainContent = ({ children }) => (
  <main
    sx={{
      display: "flex",
      flex: "1 1 auto",
      width: "100%",
      // Fixed header
      // mt: "112px",
    }}
  >
    {children}
  </main>
)

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContent

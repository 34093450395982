/**
 * 
 */
export class DatasetsAddressError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DatasetsAddressError)
    }

    this.name = "DatasetsAddressError"
    this.date = new Date()

    this.type = type

    this.message = "There has been an error fetching the datasets corresponding to the address."
  }
}

/**
 * 
 */
 export class DatasetDownloadUrlError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DatasetDownloadUrlError)
    }

    this.name = "DatasetDownloadUrlError"
    this.date = new Date()

    this.type = type

    this.message = "There has been an error requesting the dataset download url."
  }
}

/**
 * 
 */
 export class DatasetDeleteError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DatasetDeleteError)
    }

    this.name = "DatasetDeleteError"
    this.date = new Date()

    this.type = type

    this.message = "There has been an error deleting the dataset (pending order)."
  }
}
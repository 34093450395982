/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import SOCIAL from "../../content/social"

const SocialLinks = ({ containerName, invertColor = false }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "social" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 24, maxHeight: 24) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
            # ...personImage
          }
        }
      }
    }
  `)

  const numLinks = SOCIAL.length
  const invert = {}
  if (invertColor) {
    invert.filter = "brightness(0) invert(1)"
  }

  return (
    <Flex>
      {SOCIAL.map((social, index) => (
        <a
          key={`social-link-${containerName}-${social.id}`}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          title={social.name}
          sx={{
            mr:
              numLinks === index + 1 ? "0px" : ["8px", "16px", "16px", "16px"],
            height: ["20px", "20px", "24px", "24px"],
            color: "background",
            ...invert,
          }}
        >
          {/* <img
            alt={social.name}
            src={social.img}
            sx={{
              height: ["20px", "20px", "24px", "24px"],
              width: ["20px", "20px", "24px", "24px"],
            }}
          /> */}
          <Box
            sx={{
              height: ["20px", "20px", "24px", "24px"],
              width: ["20px", "20px", "24px", "24px"],
            }}
          >
            <Img
              alt={social.name}
              fluid={
                data.allFile.edges.find(img => img.node.base === social.image)
                  .node.childImageSharp.fluid
              }
            />
          </Box>
        </a>
      ))}
    </Flex>
  )
}

export default SocialLinks

/** @jsx jsx */
import { jsx, Badge as BadgeThemeUI } from "theme-ui"

const Badge = ({ variant = "circle", ml, mt, mr, mb, sx, children }) => (
  <BadgeThemeUI
    variant={variant}
    ml={ml}
    mt={mt}
    mb={mb}
    mr={mr}
    sx={{ ...sx }}
  >
    {children}
  </BadgeThemeUI>
)

export default Badge

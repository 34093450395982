/** @jsx jsx */
import { jsx } from "theme-ui"

const Div = ({ span = false, onClick, sx, title, children }) =>
  span ? (
    <span title={title} onClick={onClick} sx={{ ...sx }}>
      {children}
    </span>
  ) : (
    <div title={title} onClick={onClick} sx={{ ...sx }}>
      {children}
    </div>
  )

export default Div

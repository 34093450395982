import React from "react"

import { ColumLayout, Flex, Heading, ButtonLink } from ".."

const CONTENT = {
  cart: "The cart is empty!",
  orders: "There are no datasets",
}

const EmptyPanel = ({ type }) => {
  const getText = () => {
    if (type === "cart") return CONTENT.cart
    if (type === "orders") return CONTENT.orders
  }

  return (
    <ColumLayout centered sx={{
      minHeight: ["500px", "550px", "600px", "790px"],
      height: "auto"}}>
      <Heading variant="text.previewTitle" sx={{ mb: "50px" }}>
        {getText()}
      </Heading>
      <Flex
        sx={{
          width: "100%",
          mx: "auto",
          justifyContent: "center",
        }}
      >
        <ButtonLink to="/search" secondary>
          Search and Buy
        </ButtonLink>
      </Flex>
    </ColumLayout>
  )
}

export default EmptyPanel

import React from "react"

import { ContentPanel, Flex } from ".."

const TitleContentPanel = ({ outerSx, innerSx, children }) => (
  <ContentPanel sx={{ ...outerSx }}>
    <Flex sx={{ flex: 1, px: "32px" }}>
      <Flex
        column
        sx={{
          flex: 1,
          maxWidth: "616px",
          // maxWidth: ["100%","100%","100%","616px"],
          // px: "32px",
          // mx: "auto",
          ml: [null,null,"0px","40px"],
          py: ["50px","50px","50px","unset"],
          justifyContent: "center",
          ...innerSx,
        }}
      >
        {children}
      </Flex>
    </Flex>
  </ContentPanel>
)

export default TitleContentPanel

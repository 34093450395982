/** @jsx jsx */
import { jsx } from "theme-ui"

const Button = ({
  variant = "buttons.primary",
  title,
  onClick,
  type,
  disabled = false,
  sx,
  children,
}) => (
  <button
    title={title}
    type={type}
    onClick={onClick}
    disabled={disabled}
    sx={{ variant: disabled ? "buttons.disabled" : variant, ...sx }}
  >
    {children}
  </button>
)

export default Button

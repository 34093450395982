import React from "react"
import PropTypes from "prop-types"

import { Flex } from ".."

const BottomWrapper = ({ children }) => (
  <Flex
    sx={{
      flexDirection: ["column","row", "row", "row"],
      width: "fit-content",
      zIndex: 1,
      position: "absolute",
      bottom: "60px",
      mx: "auto",
      justifyContent: "center",
      left: 0,
      right: 0,
    }}
  >
    {children}
  </Flex>
)

BottomWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BottomWrapper


const API_ROOT = process.env.GATSBY_API_ROOT

const API_ESTIMATIONS = API_ROOT + "/estimations"
const API_BACKEND = API_ROOT + "/websitebackend/api/v1"

export const API = {
  heatmap: API_ESTIMATIONS + "/heatmap",
  points: API_ESTIMATIONS + "/points",
  purchaseSignature: API_BACKEND + "/price_signatures",
  orders: API_BACKEND + "/dataset",
  ordersLink: API_BACKEND + "/dataset/link",
  cheques: API_BACKEND + "/cheques",
  balances: API_BACKEND + "/balances",
}